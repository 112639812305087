import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'
import ChanPing from '../views/chanPing/chanPing.vue'
import LianXi from '../views/lianXi/lianXi.vue'
import AnLi from '../views/anLi/anLi.vue'
import LiuYan from '../views/liuYan/liuYan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ChanPing',
    name: 'ChanPing',
    component: ChanPing
  },
  {
    path: '/LianXi',
    name: 'LianXi',
    component: LianXi
  },
  {
    path: '/AnLi',
    name: 'AnLi',
    component: AnLi
  },
  {
    path: '/LiuYan',
    name: 'LiuYan',
    component: LiuYan
  },
]

const router = new VueRouter({
  routes
})

export default router
