<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <el-row type="flex" class="row-bg" align="middle">
        <el-col :span="5">
          <img src="../../assets/logo.jpg" class="logo" />
        </el-col>
        <el-col :span="2" :offset="5"
          ><a href="#" style="color: rgba(0, 0, 255, 0.733)">首页</a></el-col
        >
        <el-col :span="2">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link"> 关于我们 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>企业介绍</el-dropdown-item>
              <el-dropdown-item>企业文化</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link" @click="chanPing">产品展示 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>LED日光灯管</el-dropdown-item>
              <el-dropdown-item>LED商业照明</el-dropdown-item>
              <el-dropdown-item>LED工矿灯</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link" @click="anzhuanganli">
              安装案例
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>政府学校</el-dropdown-item>
              <el-dropdown-item>企业单位</el-dropdown-item>
              <el-dropdown-item>超市商场</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2"><span @click="liuYan">在线留言</span></el-col>
        <el-col :span="2"><span @click="lianXi">联系我们</span></el-col>
      </el-row>
    </el-header>
    <!-- 轮播图 -->
    <div class="luobotu" style="margin-top: 40px">
      <el-carousel height="600px" :interval="5000" arrow="always">
        <el-carousel-item v-for="item in imgArray" :key="item">
          <img :src="item" class="rightImg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 主体 -->
    <el-main>
      <h1>产品展示</h1>
      <div class="main-button"  @click="chanPing">
        <span>查看更多</span>
      </div>
      <p>
        我公司训练有索的研发部门能够提供OEM产品来满足您的需求，我们主要是生产高质里的LED管，线性灯具，简灯、轨道灯、面板、高湾、泛光灯、路灯。
      </p>
      <el-row type="flex" justify="center" class="main-img">
        <el-col :span="3">
          <img src="../../assets/rotation-1.jpg" alt="" />
        </el-col>
        <el-col :span="3">
          <img src="../../assets/rotation-2.jpg" alt="" />
        </el-col>
        <el-col :span="3">
          <img src="../../assets/rotation-3.jpg" alt="" />
        </el-col>
        <el-col :span="3">
          <img src="../../assets/rotation-3.jpg" alt="" />
        </el-col>
        <el-col :span="3">
          <img src="../../assets/rotation-5.png" alt="" />
        </el-col>
        <el-col :span="3"
          ><img src="../../assets/rotation-6.png" alt=""
        /></el-col>
      </el-row>
    </el-main>
    <!-- 案例展示 -->
    <div class="anli">
      <h1>案例展示</h1>
      <div class="anli-button" @click="anzhuanganli">
        <span>查看更多</span>
      </div>
      <p>我们正在世界各应用产品</p>
      <el-row type="flex" justify="center" style="margin-left: 120px">
        <el-col :span="6">
          <img src="../../assets/anli-1.png" alt="" />
          <p style="margin-left: 16%">一个工厂的情况</p>
          <div @click="anzhuanganli">
            <span>查看更多</span>
          </div>
        </el-col>
        <el-col :span="6">
          <img src="../../assets/anli-2.png" alt="" />
          <p style="margin-left: 20%">停车场的情况</p>
          <div @click="anzhuanganli">
            <span>查看更多</span>
          </div>
        </el-col>
        <el-col :span="6">
          <img src="../../assets/anli-3.jpg" alt="" />
          <p style="margin-left: 25%">超市的案例</p>
          <div @click="anzhuanganli">
            <span>查看更多</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="dibu">
      <el-row type="flex" justify="center">
        <el-col :span="4">
          <img
            src="../../assets/logo2.png"
            width="150px"
            height="50px"
            style="margin-top: 80px"
          />
        </el-col>
        <el-col :span="4">
          <h3>关于我们</h3>
          <el-divider style="width: 70px"></el-divider>
          <p>公司简介</p>
          <p>企业文化</p>
        </el-col>
        <el-col :span="4">
          <h3>产品展示</h3>
          <el-divider></el-divider>
          <p @click="chanPing">LED日光灯管</p>
          <p @click="chanPing">LED商业照明</p>
          <p @click="chanPing">LED工矿灯</p>
        </el-col>
        <el-col :span="4">
          <h3>安装案例</h3>
          <el-divider></el-divider>
          <p @click="anzhuanganli">政府学校</p>
          <p @click="anzhuanganli">企业单位</p>
          <p @click="anzhuanganli">超市商场</p>
        </el-col>
      </el-row>
    </div>
    <div class="dibu2">
      <p style="color: #fff">
        北京盛世亚博科贸有限公司 | 京ICP备2021032900号 
      </p>
    </div>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      imgArray: [
        require("../../assets/luobotu-1.png"),
        require("../../assets/luobotu-2.png"),
        require("../../assets/luobotu-3.png"),
      ],
    };
  },
  methods: {
    chanPing() {
      this.$router.replace("/ChanPing");
    },
    anzhuanganli() {
      this.$router.replace("/AnLi");
    },
    liuYan() {
      this.$router.replace("/LiuYan");
    },
    lianXi() {
      this.$router.replace("/LianXi");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
.el-header {
  padding: 15px 13%;
}
.logo {
  width: 80%;
  color: rgba(0, 0, 255, 0.733);
}
.el-dropdown-link {
  cursor: pointer;
  color: black;
}
.el-dropdown {
  font-size: 16px;
}
/* 主体 */
.el-main {
  width: 100%;
  height: 650px;
  margin-top: 50px;
  background-color: rgba(46, 160, 87);
}
.el-main > h1 {
  color: #fff;
  font-size: 45px;
  text-align: center;
}
.main-button {
  width: 120px;
  height: 45px;
  border: 1px solid #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
}
.main-button:hover {
  background-color: darkgreen;
  border: 1px solid darkgreen;
}
.main-button > span {
  color: #fff;
  font-size: 18px;
}
.el-main > p {
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 55px;
}
.main-img {
  margin-top: 50px;
}
.main-img .el-col-3 {
  margin-right: 40px;
}
.main-img .el-col-3 > img {
  width: 95%;
  transition: all 2s ease 0s;
}
.main-img .el-col-3 > img:hover {
  transform: scale(1.2);
}

/* 轮播图 */
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
/* 案例展示 */
.anli h1 {
  font-size: 45px;
  text-align: center;
}
.anli-button {
  width: 120px;
  height: 45px;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
  background-color: rgba(46, 160, 87);
}
.anli-button:hover {
  background-color: darkgreen;
}
.anli-button > span {
  color: #fff;
  font-size: 18px;
}
.anli > p {
  font-size: 20px;
  text-align: center;
  line-height: 55px;
}
.anli .el-col-6 > img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}
.anli .el-col-6 > div {
  width: 100px;
  height: 35px;
  margin-left: 80px;
  text-align: center;
  line-height: 39px;
  background-color: rgba(46, 160, 87);
}
.anli .el-col-6 > p:hover {
  color: rgba(46, 160, 87);
}
.anli .el-col-6 > div:hover {
  background-color: darkgreen;
}
.dibu {
  width: 100%;
  height: 210px;
  margin-top: 30px;
  background-color: rgb(70, 70, 70);
}
.el-divider--horizontal {
  width: 80%;
}
.dibu .el-col-4 > h3 {
  color: #fff;
}
.dibu .el-col-4 > p {
  color: #fff;
}
.dibu2 {
  width: 100%;
  height: 40px;
  background-color: black;
  line-height: 10px;
}
.dibu2 > p{
  margin-left: 70%;
}
.dibu .el-col-4 > p:hover{
    color: rgba(46, 160, 87);
}
</style>