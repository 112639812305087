<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <el-row type="flex" class="row-bg" align="middle">
        <el-col :span="5">
          <img src="../../assets/logo.jpg" class="logo" />
        </el-col>
        <el-col :span="2" :offset="5"><span @click="home">首页</span></el-col>
        <el-col :span="2">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link"> 关于我们 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>企业介绍</el-dropdown-item>
              <el-dropdown-item>企业文化</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link" @click="chanPing"> 产品展示 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>LED日光灯管</el-dropdown-item>
              <el-dropdown-item>LED商业照明</el-dropdown-item>
              <el-dropdown-item>LED工矿灯</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link" @click="anzhuanganli"> 安装案例 </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>政府学校</el-dropdown-item>
              <el-dropdown-item>企业单位</el-dropdown-item>
              <el-dropdown-item>超市商场</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="2"><span @click="liuYan">在线留言</span></el-col>
        <el-col :span="2"
          ><a href="#" style="color: rgba(0, 0, 255, 0.733)"
            >联系我们</a
          ></el-col
        >
      </el-row>
    </el-header>

    <div class="beijing">
      <img src="../../assets/beijing.png" alt="" />
    </div>
    <el-mian>
      <h1 style="text-align: center">联系我们</h1>
    </el-mian>
    <div class="dibu">
      <el-row type="flex" justify="center">
        <el-col :span="4">
          <img
            src="../../assets/logo2.png"
            width="150px"
            height="50px"
            style="margin-top: 80px"
          />
        </el-col>
        <el-col :span="4">
          <h3>关于我们</h3>
          <el-divider style="width: 70px"></el-divider>
          <p>公司简介</p>
          <p>企业文化</p>
        </el-col>
        <el-col :span="4">
          <h3>产品展示</h3>
          <el-divider></el-divider>
          <p @click="chanPing">LED日光灯管</p>
          <p @click="chanPing">LED商业照明</p>
          <p @click="chanPing">LED工矿灯</p>
        </el-col>
        <el-col :span="4">
          <h3>安装案例</h3>
          <el-divider></el-divider>
          <p @click="anzhuanganli">政府学校</p>
          <p @click="anzhuanganli">企业单位</p>
          <p @click="anzhuanganli">超市商场</p>
        </el-col>
      </el-row>
    </div>
    <div class="dibu2">
      <p style="color: #fff">
        北京盛世亚博科贸有限公司 | 京ICP备2021032900号
      </p>
    </div>
  </el-container>
</template>

<script>
export default {
  methods: {
    home() {
      this.$router.replace("/");
    },
    chanPing() {
      this.$router.replace("/ChanPing");
    },
    anzhuanganli() {
      this.$router.replace("/AnLi");
    },
    liuYan() {
      this.$router.replace("/LiuYan");
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}
.el-header {
  padding: 15px 13%;
}
.logo {
  width: 80%;
  color: rgba(0, 0, 255, 0.733);
}
.el-dropdown-link {
  cursor: pointer;
  color: black;
}
.el-dropdown {
  font-size: 16px;
}
.beijing > img {
  width: 100%;
  height: 250px;
  margin-top: 50px;
}
.dibu {
  width: 100%;
  height: 210px;
  margin-top: 30px;
  background-color: rgb(70, 70, 70);
}
.el-divider--horizontal {
  width: 80%;
}
.dibu .el-col-4 > h3 {
  color: #fff;
}
.dibu .el-col-4 > p {
  color: #fff;
}
.dibu .el-col-4 > p:hover{
    color: rgba(46, 160, 87);
}
.dibu2 {
  width: 100%;
  height: 40px;
  background-color: black;
  line-height: 10px;
}
.dibu2 > p{
  margin-left: 70%;
}
</style>